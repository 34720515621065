.card-container {}

.card-container .card-margin {
  padding-bottom: 29px;
}

.wrapper {
  display: flex;
  align-items: center;
  gap: 0 15px;
  margin-bottom: 40px;
}

.wrapper .info h4 {
  font-family: Bahij-regular;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: right;
}

.wrapper .info p {
  font-family: Bahij-regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: right;
  color: #8f8f8f;
}

.wrapper .image {
  width: 62px;
  height: 62px;
  border-radius: 100%;
  overflow: hidden;
}

.wrapper .image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.details h4 {
  font-family: Bahij-regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: right;
}

.details p {
  font-family: Bahij-regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: right;
  margin-top: 5px;
}

.orange-text h4,
.orange-text p {
  background: -webkit-linear-gradient(189.13deg,
      #f89a32 6.92%,
      #fa4348 114.52%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.red-text h4,
.red-text p {
  background: linear-gradient(180deg, #ff6267 0%, #fa4348 100%);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.top-margin h4 {
  margin-top: 11px;
}