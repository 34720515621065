.card-header {
  border-bottom: 1px solid #f4f7fe;
  margin-bottom: 24px;
  padding-bottom: 15px;
}

.card-header h1,
.card-header p {
  font-family: "Bahij-regular";
  font-style: normal;
  letter-spacing: 0em;
}

.card-header h1 {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;

  color: #000000;

  margin-bottom: 13px;
}

.card-header p {
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 19px;

  color: #707070;
}

.card-content {
  display: flex;
  flex-direction: column;
  /* padding-bottom: 50px; */
}

.card-content .first-section,
.card-content .second-section,
.card-content .third-section {
  display: flex;
  flex-direction: column;
  gap: 20px 0;

  padding-bottom: 35px;
  padding-left: 40px;
  padding-right: 4px;
}

.card-content .first-section,
.card-content .second-section {
  padding-bottom: 35px;
  border-bottom: 1px solid #f4f7fe;
  margin-bottom: 27px;
}

.card-content .justify-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.user-header,
.driver-header {
  padding-bottom: 15px;
  border-bottom: 1px solid #f4f7fe;
  /* margin-bottom: 27px; */
}

.user-header h4,
.driver-header h4 {
  font-family: "Bahij-regular";
  font-style: normal;
  font-size: 18px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-transform: capitalize;
}

.card-content .title,
.card-content .sub-title,
.card-content .sub-title a,
.card-content .order-status {
  font-family: "Bahij-regular";
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0em;
  text-transform: capitalize;
}

.card-content .title {
  font-weight: 600;
}

.card-content .sub-title .card-content .sub-title a {
  font-weight: 400;
}

.card-content .button {
  align-self: center;
}

.card-content .order-status {
  font-weight: 600;
  border-radius: 5px;
  width: 66px;
  height: 28px;

  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-content .order-status:hover {
  filter: brightness(1.1);
}

.card-content .order-status.completed {
  background: #13b363;
}

.card-content .order-status.pending {
  background: #ff2625;
}

.card-content .button button {
  background-color: #18a086;
  /* Green */
  border: none;
  border-radius: 10px;
  color: white;
  padding: 13px 102px;
  text-align: center;
  text-decoration: none;
  display: inline-block;

  transition: all ease 0.45s;

  font-family: "Bahij-regular";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
}

.card-content .button button:hover {
  transform: scale(0.95);
  filter: brightness(0.9);
  box-shadow: 0 0 45px rgb(0 0 0 / 5%);
}