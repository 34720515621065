/*  svg  */
.dept-info-container {
  display: flex;
  gap: 0 13px;

  position: "relative";
}
.dept-info-container::before {
  content: "";
  position: absolute;
  top: 20px;
  right: -25px;
  width: 5px;
  height: 30px;
  border-top-right-radius: 1px;
  border-top-left-radius: 13px;
  border-bottom-left-radius: 20px;
}
.red-border::before {
  background: #ef3b4d;
}
.green-border::before {
  background: #36ad89;
}

/* svg */

.dept-info-container .dept-svg {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 37px;
  height: 37px;
}
.dept-info-container .dept-text {
  align-self: center;
}
.red-svg {
  background: #ef3b4d;
}
.green-svg {
  background: #36ad89;
}
.green-svg svg {
  transform: rotate(180deg);
}

/* table header  */

.table-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-header a {
  width: unset !important;
}

/* // tool tip */

.text {
  width: 90px;
  overflow: hidden;
  cursor: pointer;
}
.tooltip {
  position: absolute;
  top: -999px;
  display: none;
  opacity: 0;
  background-color: #fff;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  transition: all ease 0.45s;
  padding: 10px 15px;
  z-index: 999;
  overflow: visible;
  visibility: visible;
}
.text:hover ~ .tooltip {
  display: block;
  background-color: #fff;
  top: -15px;
  z-index: 999;
  overflow: visible;
  visibility: visible;
  opacity: 1;
}
