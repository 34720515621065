.wrapper {
  min-width: 203px;
  background: #f9f9f9;
  border-radius: 12px;
  padding: 16px 17px 13px 21px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0 25px;
}

.wrapper .right-section svg,
.wrapper .left-section svg {
  margin-left: 8.9px;
}

.wrapper .right-section span,
.wrapper .left-section span {
  font-family: "Bahij-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: right;
  color: #545454;

  /* max-width: 50px; */
  /* border: 1px solid red; */
}