.order-status {
  font-family: "Bahij-regular";
  font-style: normal;
  font-size: 12px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;

  color: #fff;

  border-radius: 5px;

  padding: 10px 10px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.order-status:hover {
  filter: brightness(1.1);
}

.order-status.green {
  background: #13b363;
}
.order-status.red {
  background: #ff2625;
}

.order-status.orange {
  background: #ee7a4b;
}
