.card-margin {
  margin-bottom: 7px !important;
}

.card-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 1.5px 8.4px 0;
}

.info h4,
.info h6 {
  font-style: normal;
  letter-spacing: 0em;
}

.info h4 {
  font-family: "Bahij-regular";
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;

  margin-bottom: 7.3px;
}

.info h4 span {
  margin-left: 3.7px;
  font-weight: bold;
}

.info h6 {
  font-family: "Bahij-light";
  font-size: 16px;
  font-weight: 200;
  line-height: 19px;
}

.icon {
  width: 43px;
  height: 43px;
  overflow: hidden;
  border-radius: 10.84px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon svg {
  opacity: 1;
}