@media (max-width: 1500px) {}

@media (max-width: 1100px) {}

@media (max-width: 1024px) {
  .main-sidebar {
    transform: translateX(150%);
    transition: all 0.3s;
  }

  .main-head .logo {
    display: flex;
  }

  .head-wrp {
    padding: 18px;
  }

  .authWrap,
  .noificationWrap {
    display: none;
  }

  .burgerBtn .toggleSidebar {
    width: 38px;
    height: 38px;
    line-height: 38px;
  }

  .burgerBtn {
    margin: 0;
  }

  .content-wrap {
    margin: 105px auto;
    padding: 28px;
  }

  /* ***** Sidebar **** */
  .sidebarToggled .content-wrap {
    margin: 105px auto;
  }

  .sidebarToggled .main-head .head-wrp {
    padding: 18px;
  }

  .sidebarToggled .main-sidebar {
    transform: none;
    width: 292px;
  }

  .sidebarToggled .main-sidebar .side-nav ul li a~.children {
    max-height: initial;
  }

  .sidebarToggled .main-sidebar .logo .logo-info,
  .sidebarToggled .main-sidebar .side-nav ul li a span,
  .sidebarToggled .main-sidebar .side-actions a span {
    opacity: 1;
    position: relative;
  }

  .sidebarToggled .main-sidebar .side-nav ul li a,
  .sidebarToggled .main-sidebar .side-actions a,
  .sidebarToggled .main-sidebar .side-actions {
    text-align: initial;
    justify-content: normal;
  }

  .sidebarToggled .main-sidebar .side-nav ul li a svg,
  .sidebarToggled .main-sidebar .side-actions a svg {
    margin: 0 0 0 13px;
  }

  .overlay-s.fire {
    opacity: 1;
    visibility: visible;
    transition: all 0.3s;
  }

  .filter-wrp {
    margin: 0;
    padding: 30px 0 10px;
    width: 100%;
  }

  .select-filter~.filter-wrp {
    width: unset;
  }

  .main-sidebar .logo {
    justify-content: center;
    padding: 18px;
  }

  .timeWrap span {
    width: 46px;
    height: 46px;
    line-height: 46px;
    font-size: 15px;
  }

  .searchWrap .form-group {
    margin: 0;
  }

  .offScroll {
    overflow: hidden;
  }

  .switcher .knobs:before {
    transition: all 0.3s;
  }


  .main-head {
    display: flex;
  }

  .main-head .logo {
    width: auto;
  }

  .head-wrp {
    width: auto;
  }

  .users-area .card {
    height: auto;
  }

  .sidebarToggled .logo-icon {
    opacity: 1;
  }

  .dark .main-head .logo {
    background-color: #282c31;
  }
}


@media (max-width: 625px) {
  .pagination-wrapper {
    justify-content: center;
  }

  .card {
    margin-bottom: 15px;
  }

  .chart-body.pieChart .recharts-wrapper>svg {
    width: 55%;
    float: left;
    height: 140px;
  }

  .chart-body.pieChart {
    /* height: 120px !important; */
  }

  .customers-list .c-item {
    display: flex;
    flex-direction: column;
    gap: 20px 0;
  }

  .customers-list .c-item .i-img {
    width: 75px;
    height: 75px;
    margin: 0 auto 12px;
    overflow: hidden;
  }

  .users-area .card {
    height: auto !important;
  }

  .card-head h4,
  .reports-blocks .card h4 {
    font-size: 14px;
  }

  .card-head p,
  .customers-list .c-item .i-data p {
    font-size: 10px;
  }

  .customers-list .c-item .i-data h4,
  .project-item .i-head a {
    font-size: 12px;
  }

  .searchWrap .form-control {
    min-width: 140px;
    height: 38px;
    width: 140px;
  }

  .timeWrap span {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 11px;
    margin: 0 2px;
  }

  .searchWrap .form-group button {
    height: 29px;
    line-height: 29px;
    width: 29px;
  }

  .content-wrap {
    margin: 159px auto;
  }

  .filter-wrp .form-group .form-control {
    min-width: 100%;
  }

  .control-wrp .btn.btn-big {
    height: 46px;
    line-height: 46px;
    margin-bottom: 15px;
    font-size: 13px;
  }

  .control-wrp .card {
    flex-wrap: wrap;
    justify-content: center;
  }

  .control-wrp .card h5 {
    margin-bottom: 15px;
  }

  .table-pager .pagination {
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    display: block;
    white-space: nowrap;
  }

  .table-pager .pagination .page-item {
    display: inline-block;
  }

  input[type="checkbox"],
  input[type="radio"],
  input[type="checkbox"]:checked::before,
  input[type="radio"]:checked::before {
    width: 20px;
    height: 20px;
    border-radius: 3px !important;
  }

  input[type="checkbox"]:checked::before,
  input[type="radio"]:checked::before {
    line-height: 20px;
    font-size: 14px;
  }

  input[type="checkbox"]:checked::after,
  input[type="radio"]:checked::after {
    animation: click-wave1 0.65s;
  }

  @keyframes click-wave1 {
    0% {
      height: 20px;
      width: 20px;
      opacity: 0.35;
      position: relative;
    }

    100% {
      height: 30px;
      width: 30px;
      opacity: 0;
      margin: -6px;
    }
  }

  .rdt_TableCell div,
  .rdt_TableCol * {
    font-size: 13px;
  }

  .modal-dialog {
    margin: 0 !important;
    min-height: 100% !important;
  }

  .modal-content {
    border-radius: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    overflow: hidden;
    overflow-y: auto;
  }

  .modal-body {
    display: flex;
    align-items: center;
  }

  .modal-wrp .modal-icon {
    width: 80px;
    height: 80px;
  }

  .modal-wrp h4 {
    font-size: 18px;
  }

  .modal-btns .btn {
    height: 38px;
    line-height: 38px;
    font-size: 15px;
    padding: 0 30px;
  }

  .content-wrap,
  .sidebarToggled .content-wrap {
    margin-top: 0;
  }

  .main-head {
    position: relative;
  }

  .card-inner .checker input[type="checkbox"]:after {
    animation: click-wave 0.65s;
  }

  .card-inner .checker input[type="checkbox"] {
    width: 26px;
    height: 26px;
  }

  .card-inner .checker input[type="checkbox"]::before {
    line-height: 26px;
    width: 26px;
    height: 26px;
    font-size: 17px;
  }

  .card-inner {
    min-height: 120px;
  }

  .images-wrp .img-holder {
    width: 95px;
    min-width: 95px;
  }

  .custom-btn-blue {
    padding: 10px;
    font-size: smaller;
  }

  .custom-padding {
    padding: 15px;
  }



}

@media (max-width: 425px) {
  .table-pager {
    padding: 0;
  }

  .images-wrp .img-holder {
    width: 73px;
    min-width: 73px;
  }

  .select-filter,
  .select-filter select,
  .select-filter~.filter-wrp {
    width: 100%;
  }


}

/* ///////////////min width  */

@media (min-width: 768px) {


  .sidebarToggled .main-sidebar .side-nav ul li a.active~.children>li>a>span {
    padding: 0;
    margin: 0;
    opacity: 1;
  }

  .sidebarToggled .main-sidebar .side-nav ul li a.active~.children>li>a>span:before {
    display: none;
  }

  .sidebarToggled .main-sidebar .side-nav ul li a~.children {
    transition: max-height 300ms ease, background-color 300ms linear;
    max-height: inherit;
    right: 120%;
    top: 0;
    position: absolute;
    min-width: 200px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
    background: #fff;
    box-shadow: -3px 3px 3px rgb(0 0 0 / 5%);
    max-height: unset;
    height: auto;
    padding: 0;
  }

  .sidebarToggled .main-sidebar .side-nav ul li .side-nav-child:hover .children {
    opacity: 1;
    right: 100%;
    transition: all 0.3s;
    visibility: visible;
  }

  .sidebarToggled .main-sidebar .side-nav ul {
    overflow: visible;
    padding: 0;
  }

  .sidebarToggled .main-sidebar .side-nav ul li .children a span {
    opacity: 1;
    position: unset;
    font-size: 14px;
  }

  .sidebarToggled .main-sidebar .side-nav ul li a~.children li a {
    justify-content: flex-start;
    margin: 0;
    padding: 10px 15px;
  }

  .sidebarToggled .main-sidebar .side-nav {
    padding: 40px 10px;
  }


}