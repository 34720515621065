.text {
  width: 70px;
  overflow: hidden;
  cursor: pointer;
}
.tooltip {
  position: absolute;
  top: -999px;
  display: none;
  opacity: 0;
  background-color: #fff;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  transition: all ease 0.45s;
  padding: 10px 15px;
  z-index: 999;
  overflow: visible;
  visibility: visible;
}
.text:hover ~ .tooltip {
  display: block;
  background-color: #fff;
  top: -15px;
  z-index: 999;
  overflow: visible;
  visibility: visible;
  opacity: 1;
}
