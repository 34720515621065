.data-table-select-filter {
  margin-left: 25px;
  margin-bottom: 1rem;
}
.data-table-select-filter select {
  margin-top: -100px;
  border: 1px solid #e7e7e7;
  border-radius: 13.4068px;
  height: fit-content;
  min-width: 135px;

  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("../../assets/svgs/selectArrow.svg");
  background-repeat: no-repeat;
  background-position-x: 12%;
  background-position-y: 50%;
  padding: 0.65rem;
  padding-left: 2rem;
}

@media (max-width: 1100px) {
  .data-table-select-filter select {
    margin-top: 1rem;
  }
}
@media (max-width: 425px) {
  .data-table-select-filter {
    margin-left: 0;
  }
}
