/*  svg  */
.dept-info-container {
  display: flex;
  gap: 0 13px;

  position: "relative";
}
.dept-info-container::before {
  content: "";
  position: absolute;
  top: 20px;
  right: -25px;
  width: 5px;
  height: 30px;
  border-top-right-radius: 1px;
  border-top-left-radius: 13px;
  border-bottom-left-radius: 20px;
}
.red-border::before {
  background: #ef3b4d;
}
.green-border::before {
  background: #36ad89;
}

/* svg */

.dept-info-container .dept-svg {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 37px;
  height: 37px;
}
.dept-info-container .dept-text {
  align-self: center;
}
.red-svg {
  background: #ef3b4d;
}
.green-svg {
  background: #36ad89;
}
.green-svg svg {
  transform: rotate(180deg);
}

/* table header  */

.table-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-header a {
  width: unset !important;
}
