.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-width: 203px;
  background: #f9f9f9;
  border-radius: 12px;
  padding: 14px 17px 14px 21px;
}

.wrapper .first-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0 10px;
}

.wrapper .first-section .icon {
  background: #18a086;
  width: 35px;
  height: 33.9px;

  cursor: pointer;

  border-radius: 6px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper .first-section .order-number p,
.wrapper .second-section p,
.wrapper .second-section span {
  font-family: "Bahij-regular";
  font-style: normal;
  letter-spacing: 0em;
}

.wrapper .first-section .order-number p {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: #545454;
}

.wrapper .second-section p {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;

  color: #18a086;
}

.wrapper .second-section span {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;

  color: #545454;
}