.wrapper {
  min-width: 203px;
  background: #f9f9f9;
  border-radius: 12px;
  padding: 16px 17px 13px 21px;
}

.wrapper .product {
  padding-top: 10px;
}

.wrapper .product .image {
  background-color: #fff;
  height: 49px;
  width: 56px;
  border-radius: 7px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper .product .image img {
  width: 33.09px;
  height: 36px;
}

.wrapper .product p,
.wrapper .product span {
  font-style: normal;
  letter-spacing: 0em;
}

.wrapper .product p {
  font-family: "Bahij-semibold";

  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}

.wrapper .product span {
  font-family: "Bahij-light";

  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
}

.wrapper .product .price {
  font-family: "Bahij-regular";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;

  white-space: nowrap;
  overflow: hidden;
  text-align: end;

  font-size: clamp(15px, calc(0.4rem + 1vw), 22px);

  color: #18a086;
  margin-top: 7px;
}